import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { joiResolver } from '@hookform/resolvers/joi';
import PasswordComplexity from 'joi-password-complexity';
import Joi from 'joi';

const maxUsernameLen = 30;

const validationSchema = new Joi.object({
    username: Joi.string().alphanum().min(3).max(maxUsernameLen),
    email: Joi.string().email({tlds: { allow: false }}).required(),
    
    password: new PasswordComplexity({min:8, max:25, lowerCase:1, upperCase:1, numeric: 1, symbol: 1, requirementCount: 5}),
    passwordConfirm: Joi.any().equal(Joi.ref('password')).required().messages({'any.only':'passwords does not match'})
});

const CreateAccount = ({onSubmit}) => {

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: joiResolver(validationSchema)
    });


    const renderErrors = () => {
        if(Object.entries(errors).length <= 0) return;
        return (
            <div className="form-error-list">
                <ErrorMessage 
                        errors={errors} 
                        name="username" 
                        render={({message}) => <p>{message}</p>}
                    />
                    <ErrorMessage 
                        errors={errors} 
                        name="email" 
                        render={({message}) => <p>{message}</p>}
                    />
                    <ErrorMessage 
                        errors={errors} 
                        name="password" 
                        render={({message}) => <p>{message}</p>}
                    />
                    <ErrorMessage 
                        errors={errors} 
                        name="passwordConfirm" 
                        render={({message}) => <p>{message}</p>}
                    />
            </div>
        )
    }

    return (
        <form
            className="sign-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex-col">
                <input {...register('username')} type="text" placeholder="Username" />
                <input {...register('email')} type="text" placeholder="Email" />
                <input {...register('password')} type="password" placeholder="Password" />
                <input {...register('passwordConfirm')} type="password" placeholder="Confirm Password" />
                <button type="submit" className="btn btn-primary">Create Account</button>
                {renderErrors()}
            </div>
        </form>
    )
}

export default CreateAccount;