import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { tl_data } from './translations.js';

const maxUsernameLen = 30;

const validationSchema = new Joi.object({
    usernameOrEmail: Joi.alternatives().try(
        Joi.string().email({tlds: { allow: false }}),
        Joi.string().alphanum().min(3).max(maxUsernameLen)
    ).required(),
    password: Joi.string().required(),
});

const SignIn = ({onSubmit, lang}) => {

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: joiResolver(validationSchema)
    });


    const renderErrors = () => {
        console.log('errors ',errors);
        if(Object.entries(errors).length <= 0) return;
        return (
            <div className="form-error-list">
                <ErrorMessage 
                        errors={errors} 
                        name="usernameOrEmail" 
                        render={({message}) => <p>{message} </p>}
                    />
                    <ErrorMessage 
                        errors={errors} 
                        name="password" 
                    render={({ message }) => <p>{tl_data[message][lang] }</p>}
                    />
            </div>
        );
    }

    return(
        <form
            className="sign-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex-col">
                <input {...register('usernameOrEmail')} type="text" placeholder="Username or Email" />
                    {errors.usernameOrEmail && <p>{tl_data['error username'][lang]}</p>}
                <input {...register('password')} type="password" placeholder="Password" />
                    {errors.password && <p>{tl_data['error password'][lang]}</p>}
                <button type="submit" className="btn btn-primary">{tl_data['Login'][lang]}</button>
                {renderErrors()}
            </div>
        </form>
    )
};

export default SignIn;