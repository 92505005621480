import React from 'react';
import '../style.scss';

const Linkedin = (props) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" className="btn-social" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.77 75.77">
            <defs></defs>
            <path d="M859.71,58h-62a6.88,6.88,0,0,0-6.89,6.89v62a6.88,6.88,0,0,0,6.89,6.89h62a6.88,6.88,0,0,0,6.89-6.89v-62A6.88,6.88,0,0,0,859.71,58Zm-44.93,62H804.62V87.27h10.16Zm-5.19-37.37a5.92,5.92,0,1,1,5.92-5.92A5.92,5.92,0,0,1,809.59,82.59ZM852.84,120H842.69v-15.9c0-3.79-.07-8.67-5.28-8.67s-6.1,4.13-6.1,8.39V120H821.16V87.27h9.75v4.47h.14a10.68,10.68,0,0,1,9.61-5.28c10.29,0,12.19,6.77,12.19,15.57V120Z" transform="translate(-790.83 -57.97)" />
        </svg>

    )
}
export default Linkedin;